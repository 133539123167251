import { registerApplication } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
// Services
import CompassI18n, { defaultNS } from "./CompassI18n";

const Bootstrap = () => {
  const init = async () => {
    await CompassI18n.init({
      ns: defaultNS,
    });

    const routes = constructRoutes(
      document.querySelector("#single-spa-layout")
    );
    const applications = constructApplications({
      routes,
      loadApp({ name }) {
        return System.import(name);
      },
    });
    const layoutEngine = constructLayoutEngine({ routes, applications });

    applications.forEach((opts) => {
      return registerApplication({
        ...opts,
        customProps: { CompassI18n },
      });
    });
    layoutEngine.activate();
  };

  return {
    init,
  };
};

const AdmsBootstrap = Bootstrap();
export default AdmsBootstrap;
