// General
import { start } from "single-spa";
// Services
import AdmsBootstrap from "./services/Bootstrap";

// Register MFE

async function initChunks() {
  await AdmsBootstrap.init();

  const { default: NotFound } = await import(
    /* webpackChunkName: "notFound" */
    /* webpackPreload: true */ "./components/notFound"
  );

  const { default: Unsupported } = await import(
    /* webpackChunkName: "unsupported" */
    /* webpackPreload: true */ "./components/unsupported"
  );

  const { default: AskToBeInvited } = await import(
    /* webpackChunkName: "askToBeInvited" */
    /* webpackPreload: true */ "./components/askToBeInvited"
  );

  // Register WEBCOMPONENTS
  customElements.define("adms-notfound", NotFound);
  customElements.define("adms-unsupported", Unsupported);
  customElements.define("adms-asktobeinvited", AskToBeInvited);
}

initChunks();

try {
  start();
} catch (error) {
  console.error("Failed initializing root");
}
